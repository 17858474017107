import type { PartialRecord } from '../helpers/types';
import { LOCALES } from './locales';
import type { TLocale, TLocaleDetails } from './types';

export const AVAILABLE_LANGUAGES: PartialRecord<TLocale, TLocaleDetails> = {
    [LOCALES.EN_GB]: {
        code: 'en-GB',
        name: 'English (United Kingdom)'
    },
    [LOCALES.SV]: {
        code: 'sv-SE',
        name: 'Svenska'
    },
    [LOCALES.ES]: {
        code: 'es-ES',
        name: 'Spanish'
    }
} as const;

export const CURRENCY_SYMBOLS = {
    EUR: '\u20AC', // €
    GBP: '\u00A3', // £
    NOK: 'kr',
    SEK: 'kr',
    CAD: '\u0024', // $
    NZD: '\u0024', // $
    INR: '\u20B9', // ₹
    USD: '\u0024' // $
} as const;
