export const LOCALES = {
    ES: 'es-ES',
    EN: 'en',
    EN_GB: 'en-GB',
    EN_CA: 'en-CA',
    EN_NZ: 'en-NZ',
    ON: 'en-ON',
    FI: 'fi-FI',
    SV: 'sv-SE',
    IE: 'en-IE',
    NO: 'nb-NO',
    ROG: 'en-ROG',
    ROW: 'en-ROW'
} as const;
