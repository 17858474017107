import { AVAILABLE_MARKETS } from './constants';
import type { TMarket, TMarketSlug } from './types';

const marketSlugs: TMarketSlug[] = Object.keys(AVAILABLE_MARKETS) as TMarketSlug[];

export const getAvailableMarketSlugs = (): TMarketSlug[] => marketSlugs;

export const getMarketDetailsBySlug = (slug: TMarketSlug): TMarket => {
    return AVAILABLE_MARKETS[slug];
};
