import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0,10,2,3,6,8];

export const dictionary = {
		"/[[market=isMarket]]/iddqd": [23,[10]],
		"/[[market=isMarket]]/iddqd/error-pages": [24,[10]],
		"/[[market=isMarket]]/iddqd/error-pages/maintainance": [26],
		"/[[market=isMarket]]/iddqd/error-pages/[code]": [~25],
		"/[[market=isMarket]]/iddqd/registration-playground": [27,[10]],
		"/[[market=isMarket]]/iddqd/tools": [~28,[10]],
		"/(noshell)/[[market=isMarket]]/(payments)/jc-intermediate-redirection-callback": [18,[9]],
		"/(noshell)/[[market=isMarket]]/(payments)/jc-pay-and-play": [~19,[9]],
		"/(noshell)/[[market=isMarket]]/(payments)/jc-redirection-callback": [~20,[9]],
		"/(content)/(no-hub)/[[market=isValidMarket]]/more": [~12,[2]],
		"/(noshell)/[[market=isMarket]]/(play-games)/practise/[gameSlug]": [~21,[9]],
		"/(noshell)/[[market=isMarket]]/register": [~22,[9]],
		"/(content)/(hub)/[...pathname=isValidHubContentUrl]": [~11,[2,3]],
		"/(content)/(overlay)/[...pathname=isValidContentOverlayUrl]": [13,[2,4,5]],
		"/(content)/(player-hub)/[...pathname=isPlayerHubContentUrl]": [~14,[2,6]],
		"/(content)/(sports-hub)/[...pathname=isValidSportsHubContentUrl]": [~15,[2,7]],
		"/(content)/(wallet-hub)/[...pathname=isWalletHubContentUrl]": [~16,[2,8]],
		"/(noshell)/[...pathname=isValidNoShellUrl]": [~17,[9]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';